import React, { useEffect } from "react"

import { Container, Row, Col } from "reusecore/Layout"
import { SectionTitle } from "reusecore/SectionTitle"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"

import GifImage from "assets/images/giphy.gif"
import UserMapWrapper from "./userMap.style"
import { Link } from "gatsby"

const UserMap = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://files.coinmarketcap.com/static/widget/coinMarquee.js";
    script.async = true;
    script.type = "text/javascript";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
    <UserMapWrapper>
      <Box className="usermap-wrapper">
        <Container className="fluid">
          <Row>
            <Col className="sm-12 md-10 offset-md-1">
              <SectionTitle className="text-center">
                <Heading>No Barriers to Entry and No Borders</Heading>
                <Text>
                  When we say we’re a decentralised platform, we mean it. Join our growing network and collaborate with music creators and fans from all over the world.
                </Text>
              </SectionTitle>



              <Box className="btn-wrapper text-center">
                <Link to="#" className="btn btn-fill">
                  Join Now
                </Link>
              </Box>
              <div width="500" height="200" id="coinmarketcap-widget-marquee" coins="1,1027,825,8358" currency="USD" theme="light" transparent="false" show-symbol-logo="true"></div>
            </Col>
          </Row>
          <Row>
            <Col className="md-12">
              <Box className="usermap-block bg-black gif-center">
                <Image
                  src={GifImage}
                  className="about-image img-responsive"
                  alt="cryptik about image"
                />
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </UserMapWrapper>
  )
}

export default UserMap
