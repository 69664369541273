import React from "react"

import {Row, Col} from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"

import data from "assets/data/service"
import ServiceSectionWrapper from "./service.style"
import Marquee from "react-fast-marquee";

const Service = () => {
    return (
        <ServiceSectionWrapper id="service">
            <Row>
                <Col className="md-12">
                    <Heading className="text-center">
                        Upcoming Projects & Partnerships
                    </Heading>
                    <Box className="service-item-wrapper" style={{paddingTop: 80}}>
                        <Row className="sm-none">
                            <Marquee gradient={false}>
                                {data.services.map((service, index) => (
                                    <Col className="lg-3 sm-6 xs-12" key={index}>
                                        <Box className="service-item text-center">
                                            <a href={service.link ? service.link: "javascript:void(0);"}
                                               target={service.link ? "_blank": ""}>
                                                {service.title === 'Clout Network' ?
                                                    <img src={service.icon} alt="cryptik service icon"/>:
                                                    <img src={service.icon} style={{maxWidth: 250, maxHeight: 70}}
                                                         alt="cryptik service icon"/>
                                                }
                                                <Heading as="h3">{service.title}</Heading>
                                            </a>
                                        </Box>
                                    </Col>
                                ))}
                            </Marquee>
                        </Row>
                        <Row className="dm-block">
                            {data.services.map((service, index) => (
                                <Col className="lg-3 sm-6 xs-12" key={index}>
                                    <Box className="service-item text-center">
                                        <a href={service.link ? service.link: "javascript:void(0);"}
                                           target={service.link ? "_blank": ""}>
                                            <img src={service.icon} style={{maxWidth: 250, maxHeight: 70}}
                                                 alt="cryptik service icon"/>
                                            <Heading as="h3">{service.title}</Heading>
                                        </a>
                                    </Box>
                                </Col>
                            ))}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </ServiceSectionWrapper>
    )
}

export default Service
