import styled from "styled-components";

const UserMapWrapper = styled.div`
  padding: 0px 0;

  .usermap-wrapper {
    img {
      margin-top: 37px;
    }
  }
  
  .btn-wrapper{
    margin-top: 35px;
  }
  .about-image {
    width: 1500px;
    height: 600px;
  }
  .gif-center {
    display: flex;
    justify-content: center;
  }
  .btn-fill {
    font-size: 16px;
    padding: 18px 35px;
    background: #ffffff;
    color: ${(props) =>
      props.theme.linkColor ? props.theme.linkColor : "#1F2AD5"};
  }

  @media only screen and (max-width: 912px) {
    .heading{
      font-size: 34px;
    }
    .about-image {
      width: 900px;
      height: 350px;
    }
  }
  @media only screen and (max-width: 700px) {
    .heading{
      font-size: 34px;
    }
    .about-image {
      width: 700px;
      height: 350px;
    }
  }
  @media only screen and (max-width: 568px) {
    .heading{
      font-size: 30px;
    }
    .about-image {
      width: 600px;
      height: 250px;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 20px 0;
    .about-image {
      width: 400px;
      height: 200px;
    }
  }
`;

export default UserMapWrapper;
