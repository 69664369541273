import React from "react"
import { Link } from "gatsby"
import CountdownTimer from "react-component-countdown-timer"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"

import {
  FaBitcoin,
  FaCcMastercard,
  FaCcVisa,
  FaCcDiscover,
} from "react-icons/fa"

import CoinFundWrapper from "./coinFund.style"

const CoinFund = () => {
  const settings = {
    count: (new Date("Jul 11, 2021 16:37:52").getTime() - new Date().getTime()) / 1000,
    showTitle: true,
    size: 60,
    labelSize: 14,
    backgroundColor: "transparent",
    color: "#fff",
    dayTitle: "Days",
    hourTitle: "Hours",
    minuteTitle: "Minutes",
    secondTitle: "Seconds",
    id: "countdownwrap"
  }

  return (
    <CoinFundWrapper id="token">
      <Container>
        <Row>
          <Col className="lg-6 md-12 ">
            <Box className="coin-fund-content-left">
              <SectionTitle>
                <SectionBackground>
                  <Heading>
                  Want to Join Our Music Blockchain Co-Op Platform?

                  </Heading>
                </SectionBackground>
                <Text>
                  Be among the first to experience the paradigm shift in the music industry. Purchase membership to become a premium member and start earning rewards. It’s that simple!

                </Text>
              </SectionTitle>

              <Box className="btn-wrapper">
                <a href="https://www.wallet.potentiam.io/" target="_blank" className="btn btn-fill">
                  Membership
                </a>
              </Box>
            </Box>
          </Col>
          <Col className="lg-6 md-12 countdown-wrap">
            <Text className="text-center">Count Down To Beta Test</Text>
            <Box className="countdown-wrapper">
              <CountdownTimer {...settings} />
            </Box>
            <Box className="progressbar-wrapper">
              <Box className="progressbar"/>
            </Box>


          </Col>
        </Row>
      </Container>
    </CoinFundWrapper>
  )
}

export default CoinFund
