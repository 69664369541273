
export default {
  faq: [
    {
        items: [
            {
                title: "How can blockchain disrupt the music industry?",
                body: "Blockchain technology is shaping up to disrupt the music industry in a big way. Platforms like Potentiam tip the scales away from middlemen and towards artists and fans by creating space for people to manage their own music output and consumption. This peer-to-peer system is safer, more transparent and more cost-effective."
            },
            {
                title: "How can I participate in the Potentiam Network?",
                body: "Joining Potentiam couldn’t be simpler. All you have to do is sign up as a user and support your choice of campaigns to start earning. Or sign up as an artist or brand to launch campaigns to create a buzz. Remember to check out our premium membership program for exclusive rewards and benefits. Everyone is a winner on Potentiam!"
            },
            {
                title: "What will I find in the Potentiam Network?",
                body: "Potentiam offers users the chance to experience and participate in the music industry like never before. The network will consist of four main modules: digital sales, physical sales, sync licensing and a music network. Members will have the opportunity to earn PTM tokens for their skills and expertise."
            },
            {
                title: "Don’t Get Left Behind, Get in the Loop",
                body: "Sign up today for all the latest developments and updates. We can’t wait to hear from you!"
            },
        ]
    }
  ],
};
