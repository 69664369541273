import serviceIcon1 from "assets/images/service/bliiink.png";
import serviceIcon2 from "assets/images/service/copyrightlogo.png";
import clout from "assets/images/service/Clout-Network.png";
import serviceIcon3 from "assets/images/service/service-icon-3.svg";
import serviceIcon4 from "assets/images/service/service-icon-4.svg";

export default {
  services: [
    {
      icon: serviceIcon1,
      title: "Bliiink",
      link: "https://bliiink.co.uk/"
    },
    {
      icon: serviceIcon2,
      title: "Audiosnapp",
      link: ""
    },
    {
      icon: clout,
      title: "Clout Network",
      link: "https://www.clout.network/"
    },
    {
      icon: serviceIcon3,
      title: "NFT",
      link: ""
    },
  ],
};
